import React from "react";
import ReactPlayer from 'react-player/youtube';
import { Parallax } from 'react-scroll-parallax';
import Container from 'react-bootstrap/Container';
import { useController } from 'react-scroll-parallax';


function MyWork(){
    const { parallaxController } = useController();
    return <div id="myWork" >
 <Container className="parallax-outer" fluid="true">

 <h1 className="my-work"><Parallax  y={[0,-30]}  ></Parallax></h1>

 <div className="row">

 <div className="column">
 <Parallax  y={[-5,5]}  tagOuter="figure" >
     <img onLoad={parallaxController.update()} src="/media/1598276938955.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598277196097.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()}  src="/media/1598277728025.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598279324414.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598278410985.jpg" alt="hosam photography" style={{width:"100%"}} />
     <ReactPlayer url='https://youtu.be/B4NAAKfhYoQ' width='100%' hight='392' light='https://instagram.fkwi1-2.fna.fbcdn.net/v/t51.2885-15/e35/41310145_953170924870292_1797805807196443662_n.jpg?_nc_ht=instagram.fkwi1-2.fna.fbcdn.net&_nc_cat=108&_nc_ohc=-MvvcKdlhWQAX8IQdyt&oh=946b594d120da472a9c2f34dac3267b2&oe=5F75514B'/>
</Parallax>
 </div>
 <div className="column">
     <img onLoad={parallaxController.update()} src="/media/1598276968961.jpg" alt="hosam photography" style={{width:"100%"}}/>
     <img onLoad={parallaxController.update()} src="/media/1598291736773.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598279445481.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598278456941.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598278385995.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598278444947.jpg" alt="hosam photography" style={{width:"100%"}} />
     <ReactPlayer url='https://www.youtube.com/watch?v=LgtpK4gz80o' width='600' hight='463' light='https://instagram.fkwi1-2.fna.fbcdn.net/v/t51.2885-15/e35/26871975_155142278609588_472981688629067776_n.jpg?_nc_ht=instagram.fkwi1-2.fna.fbcdn.net&_nc_cat=108&_nc_ohc=PVcc_asOsUYAX_76Zhu&oh=8307ffc1fb2d62306e54a1fedb2855af&oe=5F756336'/>
 </div>

 <div className="column">
 <Parallax  y={[-3,3]}  tagOuter="figure" >
     <img onLoad={parallaxController.update()} src="/media/1598276968539.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598277985415.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598279307138.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598277137198.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598277137335.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598277137455.jpg" alt="hosam photography" style={{width:"100%"}} />
     </Parallax>
 </div>
 <div className="column">
 <Parallax  y={[5,-5]}  tagOuter="figure" >
     <img onLoad={parallaxController.update()} src="/media/1598277078557.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598277985124.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598277253765.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598277601575.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598279234907.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598277971781.jpg" alt="hosam photography" style={{width:"100%"}} />
     <img onLoad={parallaxController.update()} src="/media/1598279297213.jpg" alt="hosam photography" style={{width:"100%"}} />
     </Parallax>
 </div>
 </div>

</Container>
    </div>
    
}

export default MyWork;