import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import MyWork from "./MyWork";
import About from "./About";
import Tilt from 'react-parallax-tilt';
import Container from 'react-bootstrap/Container';
import LazyLoad from 'react-lazyload';
import ScrollToTop from 'react-scroll-up';


function App() {

return <div>
<Container fluid="true" >
<Header/>
<Container fluid="false" className="parallax"  >

 {/* <div id="scene" data-relative-input="true"> */}
 <Tilt 
 className="parallax-effect-img"
 gyroscope={true}
    tiltMaxAngleX={4}
    tiltMaxAngleY={4}
    perspective={1000}
    transitionSpeed={9000}
    reset={true}
   //  scale={1.001}
    
  
 >

<img src="/carfg.png"  width="100%" className="inner-element" alt="car" />
 </Tilt>
 </Container>
      

      


<LazyLoad height={200}>
<MyWork/>
</LazyLoad>
<LazyLoad height={200}>
<About/>
</LazyLoad>

<Footer/>
<ScrollToTop showUnder={160}>
<i class="fas fa-angle-up fa-3x"></i>
</ScrollToTop>
</Container>
</div>

}


export default App;