import React from "react";


function About(){
return <div id="about">
 <img src="hosam-photo.png" className="profile" alt="hosam"/>
<h2>Hosam Al-Sayed</h2>
<p className="about-section">Kuwait-based Photographer/Videographer and Web-Developer, holder of a bachelor degree in Computer Science, During my university life, I practiced photography and video making,
  I always had love and passion in Tech, Filmmaking, Astronomy, Music and Cars.<br/>P.S. just like this Website.. I made it myself ;).</p>
</div>
}
export default About;