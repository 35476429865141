import React from "react";

function Footer(){
const year = new Date().getFullYear();
return <footer id="footer">
<div>
 <a href="https://twitter.com/UndergroundAut0" className="fab fa-twitter social-media"> </a>
 <a href="https://www.facebook.com/underground1auto" className="fab fa-facebook-f social-media"> </a>
  <a href="https://www.instagram.com/underground_auto" className="fab fa-instagram social-media"> </a>
   <a href="mailto:underground_auto@hotmail.com" className="fas fa-envelope social-media"> </a> 
   </div>
    <p>© Underground Auto Copyright {year}</p>

  </footer>

}
export default Footer;