import React from "react";
import { Nav, Navbar } from 'react-bootstrap';

function Header(){

    return <Navbar  collapseOnSelect expand="lg" className="bg-navbar" variant="dark"  >
  <Navbar.Brand><img src="/logo.png"  
        height="80rem" alt="logo"/></Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav  className="justify-content-end ml-auto">
      <Nav.Link className="nav-link" href="#myWork" >My Work</Nav.Link>
      <Nav.Link className="nav-link" href="https://www.inprnt.com/gallery/underground_auto/" style={{fontFamily: 'PT Sans Caption'}}>Store</Nav.Link>
      <Nav.Link href="mailto:hosam@undergroundauto.me" style={{fontFamily: 'PT Sans Caption'}} >Contact Me</Nav.Link>
      <Nav.Link href="#about" style={{fontFamily: 'PT Sans Caption'}} >About</Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>



}

export default Header;